#page-container {
  position: relative;
  min-height: 100vh;
}

#content-wrap {
  padding-bottom: 8rem;
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 13.3rem;
}

.animate-img {
  animation-name: example;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes example {
  0% {
    width: 35vh;
    height: auto;
    opacity: 0.5;
  }
  50% {
    width: 45vh;
    height: auto;
    opacity: 1.0;
  }
  100% {
    width: 35vh;
    height: auto;
    opacity: 0.5;
  }
}