.navbar {
    background-color: rgba(237,190,156,0.3)
}

.top-nav-collapse {
    background-color:rgb(237,190,156);
    opacity: 1;
}

@media only screen and (max-width: 768px) {
    .navbar {
        background-color: rgb(237,190,156);
        opacity: 1;
    }
}